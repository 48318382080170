.ant-calendar{
    .ant-calendar-disabled-cell{
        width: 24px !important;
    }
    .ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before{
        //width: 0px;
        opacity: 0;
    }
    .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before{
        opacity: 0;
    }
    .ant-calendar-selected-day .ant-calendar-date{
        background: unset;
    }
    .ant-calendar-selected-date {
        .ant-calendar-date{
        background: rgba(255, 205, 0, 1);
        }
    }
    .ant-calendar-next-month-btn-day .ant-calendar-date, .ant-calendar-last-month-cell .ant-calendar-date{
        color: unset;
    }
    .ant-calendar-date:hover, .ant-calendar-next-month-btn-day .ant-calendar-date:hover, .ant-calendar-last-month-cell .ant-calendar-date:hover{
        background: rgba(255, 205, 0, 0.25)
    }
    .ant-calendar-next-month-btn-day .ant-calendar-date:hover, .ant-calendar-next-month-btn-day .ant-calendar-date:hover{
        color: unset;
    }
    .ant-calendar-disabled-cell .ant-calendar-date {
        color: rgba(0, 0, 0, 0.1);
        background: rgba(0,0,0,0.015);
        width: 24px;
    }
    .ant-calendar .ant-calendar-disabled-cell{
        background: unset;
    }
}

//  Non selected - Month:
//      ant-calendar-cell
//      ant-calendar-last-day-of-month 
//      ant-calendar-disabled-cell-first-of-row 
//      ant-calendar-selected-day
//      ant-calendar-disabled-cell
//
//  Selected
//      ant-calendar-cell
//      ant-calendar-selected-date
//      ant-calendar-selected-day
//
//