.app-container-wide{
	// background: red;
	// padding:20px;
}
.container-narrow{
	// background:yellow;
	@media(min-width: 768px){
		width: 900px;
		margin:0 auto;
	}
}