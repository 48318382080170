@import './variables';
@import './buttons';
@import './fonts';
@import '../layouts/layout';
@import '../pages/Preinscripciones/preinscripciones';
@import '../pages/Reuniones/reuniones';
@import '../components/ErrorComponent/errorcomponent';
@import '../components/DatePicker/datepicker';

#root{
	font-family: 'Sailec', sans-serif;
	color:$greyEgg;
}

h1, h2{
	color: $greyEgg !important;
}



.input-error{
	color:red;
	display: block;
	margin-top: 10px;
}

// Descripciones superiores a bloques - instrucciones - detalles - label
.block-label{
	display: block;
	font-size: 1.25rem;
	font-weight: 300;
	margin-bottom: 5px;
}

.block-desc{
	display: block;
	font-size: 1rem;
	font-weight: normal;
	opacity: .6;
	margin-bottom: 10px;
}



// Estilos generales de todos los inputs de esta página
input{
	background: none;
	border:none;
	-webkit-appearance: none;
	border-radius:20px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	height: 86px;
	outline:none;
	transition: all 0.3s ease;
	width: 100%;
	padding: 30px;
	font-size: 1.365rem !important;
	// margin:10px;
	color: #90999D !important;
}
select {
	@extend input;
	-webkit-appearance: none; 
	-moz-appearance: none;
	appearance: none;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	background: white url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 97% 50% !important;
	background-size:3% !important;
	color: #90999D;
	padding:20px 30px;	
	font-size: 1.365rem;
	&[value]{
		font-size: 1.365rem;
	}
	&[placeholder]{
		font-size: 1.365rem;
		color:$lightGreyEgg;
	}
	&:hover{
		cursor: pointer;
	}
}

textarea{
	@extend input;
	padding-top:30px;
	min-height: 200px;
}