.block-error{
	margin-top: 40px;
	text-align: center;
	justify-content: center;
	display:flex;
	flex-direction: column;
	padding: 30px;
	flex-grow:1;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	border-radius:20px;
	background-image: linear-gradient(to left, #e81e1e 99%, #940d0d 0%);
	background: rgb(148,13,13);
	background: linear-gradient(90deg, rgba(148,13,13,1) 0%, rgba(232,30,30,1) 99%);
    color: white;
	.error-btn{
		font-size: 3rem;
		margin: auto;
		margin-bottom: 10px;
	}
	.error-msg{
		font-family: Sailec;
		font-size: 1.7rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.01rem;
		text-align: center;
		color: #ffffff;
		margin-bottom: 0.3rem;
	}
	.error-desc{
		font-family: Sailec;
		font-size: 1.3rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.01rem;
		text-align: center;
		color: #ffffff;
	}
}