.preinscripciones-container{
	padding: 32px 20px;
	@media(min-width:768px){
		padding:40px 0;
	}
	.preinscripciones-heading{
		.preinscripciones-logo{
			width: 38px;
			margin-bottom: -28px;
			@media(min-width:768px){
				float: right;
				width: 64px;
				left: 0; right: 0;
				margin: auto;
			}
		}
		.preinscripciones-title{
			margin-top: 50px;
			h1{
				font-weight: bold;
				letter-spacing: -0.083rem;
				font-size: 2rem;
				line-height: 2.3rem;
					@media(min-width: 768px){
					width: 70%;
					font-size: 3rem;
					line-height: 3.5rem;
				}
			}
		}
		.title-desc{
			font-size: 2.2rem;
			letter-spacing:-0.05rem;
			font-weight: lighter;
			line-height: 2.1rem;
			color:$lightGreyEgg;
			@media(max-width: 768px) {
				font-size: 1.5rem;
			}
		}
	}

	.preinscripciones-form{
		display:flex;
		flex-direction: column;
		h2{
			font-weight: bold;
			letter-spacing:-0.045rem;
			font-size: 1.625rem;
			margin-bottom: 20px;
		}
		.block{
			margin:30px 0;
			.inputs-grid{
				display:flex;
				flex-flow:row wrap;
				.info-item{
					margin:10px;
					flex: 1 1 0;
					flex-basis:100%;
					@media screen and (min-width:768px){
						flex-basis:33.333%;
					}
					&.w-100{
						width: 100%;
					}
					.select-input{
						height:86px;
						flex-grow:1;
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
						border-radius:20px;
						width: 100%;
						// margin-top: 15px;
						.ant-select-selection{
							height:86px;
							padding-left: 30px;
							border:none;
							display: flex;
							//justify-content: center;
							align-items: center;
							border-radius:20px;
							.ant-select-selection-selected-value[title]{
								font-size: 1.365rem;
								color:$lightGreyEgg;
							}
						}
					}
				}
			}
			.description{
				color: #37474F;
				font-size: 16px;
				letter-spacing: -0.44px;
				line-height: 22px;
				.bold{
					font-weight: bold;
				}
			}
		}
		.disclaimer-block{
			font-size: 18px;
			line-height: 26px;
			color: lighten($greyEgg, 20%);
			margin-top: 30px;
		}

		.block-submit{
			text-align: center;
			margin-top:50px;
		}
	}
}


.block-web-link{
	background:$eggColor;
	padding:100px 20px;
	text-align: center;
	.info{
		h2{
			font-size: 2.062rem;
			letter-spacing:-0.05rem;
			font-weight: bold;
			line-height: 2rem;
		}
	}
}

.loading-page-container{
	display: flex;
	flex-grow:1;
	justify-content: center;
	align-items:center;
	height:100vh;
}

.result-container{
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	padding:100px;
	border-radius:20px;
	h1{
		font-size: 3rem;
		font-weight: bold;
		letter-spacing:-0.08rem;
		margin: 0;
		margin-top: 30px;
	}
	.grey-span{
		color:$lightGreyEgg;
		font-size: 1.875rem;
		letter-spacing: -0.05rem;
		font-weight: 300;
		margin-top: 20px;
	}
	span{
		display:block;
	}
	.block-date{
		display:flex;
		align-items:center;
		margin-top: 40px;
		.bar{
			margin-right: 30px;
		}
		span{
			font-size: 3rem;
			line-height: 3.6rem;
			font-weight: 300;
			margin: 0;
			padding: 0;
		}
	}
	.transfer-data-list{
		margin-top: 20px;
		ul{
			list-style: none;
			display: block;
			margin-left: 0;
			padding-left: 0;
			li{
				font-size: 22px;
				// color:red !important;
			}
		}
		
	}

}

.block-send-reciept{
	background-color: $eggColor;
	padding:30px;
	border-radius: 15px;
	p{
		line-height: 30px !important;
		a{
			color:$greyEgg;
			text-decoration: underline;
		}
	}
	ul{
		list-style-type: none;
		padding-left: 0;
		li{
			font-size: 1.375rem;
			margin:10px 0;
			line-height: 1.8rem;
		}
	}
}