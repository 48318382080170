.reuniones-container{
	padding: 32px 20px;
	@media(min-width:768px){
		padding:40px 0;
	}
	.reuniones-heading{
		.reuniones-logo{
			width: 38px;
			margin-bottom: -28px;
			@media(min-width:768px){
				float: right;
				width: 64px;
			}
		}
		.reuniones-title{
			margin-top: 50px;
			h1{
				font-weight: bold;
				letter-spacing: -0.083rem;
				font-size: 2rem;
				line-height: 2.3rem;
					@media(min-width: 768px){
					width: 70%;
					font-size: 3rem;
					line-height: 3.5rem;
				}
			}
		}
		.title-desc{
			font-size: 2.2rem;
			letter-spacing:-0.05rem;
			font-weight: lighter;
			line-height: 2.1rem;
			color:$lightGreyEgg;
			@media(max-width: 768px) {
				font-size: 1.5rem;
			}
		}
	}
	.reuniones-form{
		h2{
			font-weight: bold;
			letter-spacing:-0.045rem;
			font-size: 1.625rem;
			margin-bottom: 20px;
		}
		// Estilos generales de todos los inputs de esta página
		input{
			background: none;
			border:none;
			border-radius:20px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			-webkit-appearance: none;
			height: 86px;
			outline:none;
			transition: all 0.3s ease;
			&[value]{
				font-size: 1.365rem;
			}
			&[placeholder]{
				font-size: 1.365rem;
				color:$lightGreyEgg;
			}
			&.selected{
				background:$greyEgg;
				color:$eggColor;
			}
			&:hover{
				cursor:pointer;
			}
		}
		.block-one{
			margin-top: 40px;
			.block-one-buttons{
				display: flex;
				flex-direction: column;
				@media(min-width:768px){
					flex-direction: row;
					justify-content: space-between;
				}
				// Individuales de esta fila
				input{
					flex-grow:1;
					flex-shrink: 1;
					margin:15px 5px;
					@media(min-width:768px){
						width: 0;

					}
					&:first-child{
						// margin-bottom: 15px;
						@media(min-width:768px){
							// margin-right: 15px;
							// margin-bottom: 0px;
						}
					}
					&:last-child{
						// margin-top: 15px;
						@media(min-width:768px){
							// margin-top: 0px;
							// margin-left: 15px;
						}
					}
				}
			}
			.aclaracion-salud{
				margin: 12px 5px;
				font-size: 0.9rem;
				@media(max-width: 768px){
					text-align: center;
				}
			}
		}
		.block-two{
			margin-top: 40px;
			.block-two-buttons{
				.datepicker-component{
					// background:red;
					display:flex;
					flex-flow:column wrap;
					align-items:center;
					
					@media(min-width:768px){
						flex-flow:row wrap;
					}
					flex-grow:1;
					align-content:center;
					justify-content: center;
					.ant-calendar-picker-input{
						//text-align: center;
						text-align: left;
						@media(min-width: 768px){
						}
						&[placeholder]{
							padding-left: 30px;
						}
					}
					.datepicker-container{
						float: left;
						width: 65%;
						@media(max-width:768px){
							width: 100%;
						}
						.datepicker-input{
							// background:red;
							flex-grow:1;
							flex-basis:50%;
							width: calc( 100% - 20px);
							margin: 10px;
							&[placeholder]{
								padding-left:30px;
							}
							//margin-right: 15px;
						}
					}
					.ant-input-disabled{
						background:#f5f5f5;
					}
					.turnpicker-container {
						width: 35%;
						@media(max-width:768px){
							width: 100%;
						}
						.select-input{
							height:86px;
							flex-grow:1;
							box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
							-webkit-appearance: none;
							border-radius:20px;
							width: calc( 100% - 20px );
							margin: 10px;
							.ant-select-selection{
								height:86px;
								border:none;
								display: flex;
								//justify-content: center;
								padding-left: 30px;
								align-items: center;
								border-radius:20px;
								.ant-select-selection-selected-value[title]{
									font-size: 1.365rem;
									color:$lightGreyEgg;
								}
							}
							.ant-select-selection__rendered{
								margin: 0px
							}
						}
					}
				}
			}
		}
		.block-three{
			margin-top: 40px;
			display:flex;
			flex-direction: column;
			h2{
				font-weight: bold;
				letter-spacing:-0.045rem;
				font-size: 1.625rem;
				//margin-top: 20px;
			}
			.block-three-inputs{
				.info-item{
					float:left;
					width: 100%;
					@media(min-width:768px){
						width: 50%;
					}
					input{
						margin:10px;
						padding-left:30px;
						width: calc(100% - 20px)
					}
				}
				
			}
		}
		.block-submit{
			text-align: center;
			margin-top:50px;
			// background:red;
		}
	}
}

.error-info{
	display:flex;
	margin: 16px;
	height:86px;
	flex-grow:1;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	border-radius:20px;
	width: 100%;
	margin-top: 15px;
	@media(min-width:768px){
		width: auto;
		//@at-rootmargin-top: 0px;
	}

	float:left;
	width: 100%;
	@media(min-width:768px){
		width: 100%;
	}
	input{
		margin:10px;
		padding-left:30px;
		width: calc(100% - 20px)
	}
}

.block-info{
	margin-top: 50px;
	padding:100px 0;
	@media(max-width: 768px){
		margin-top: 20px;
		padding: 45px 0;
	}
	background:$greyEgg;
	color:white;
	font-weight: 300;
	font-size: 1.125rem;
	letter-spacing:-0.031rem;
	h3{
		font-weight: bold;
		font-size: 0.875rem;
		color:white;
		letter-spacing:-0.024rem;
		a{
			color:white;
		}
	}
	.container-narrow{
		display:flex;
		flex-flow: row wrap;
		
		.block-info-item{
			flex-basis:100%;
			padding:0 35px;
			margin:20px 0;
			@media(min-width:768px){
				margin:0;
				flex-basis:33%;
			}
		}
	}
}

.block-web-link{
	background:$eggColor;
	padding:100px 20px;
	text-align: center;
	.info{
		h2{
			font-size: 2.062rem;
			letter-spacing:-0.05rem;
			font-weight: bold;
			line-height: 2rem;
		}
	}
}

.loading-page-container{
	display: flex;
	flex-grow:1;
	justify-content: center;
	align-items:center;
	height:100vh;
}

.result-container{
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	padding:72px 72px 72px 100px;
	border-radius:20px;
	margin-top: 32px;
	@media(max-width: 768px) {
		padding: 48px 32px;
		margin: 10px;
	}
	.reuniones-logo{
		width: 24px;
		float: right;
		margin-top: -30px;
		margin-right: -12px;
		@media(min-width:768px){
			width: 36px;
			margin-top: -32px;
			margin-right: -32px;
		}
	}
	h1{
		font-size: 3rem;
		font-weight: bold;
		letter-spacing:-0.08rem;
		margin: 0;
		margin-top: 30px;
		@media(max-width: 768px) {
			margin-top: 20px;
			//margin-bottom: 20px;
			font-size: 2.0rem;
		}
	}
	.grey-span{
		color:$lightGreyEgg;
		font-size: 1.875rem;
		letter-spacing: -0.05rem;
		font-weight: 300;
		margin-top: 10px;
		@media(max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	.check-circle {
		@media(max-width: 768px) {
			width: 64px
		}
	}
	span{
		display:block;
	}
	.block-date{
		display:flex;
		align-items:center;
		margin-top: 40px;
		.bar{
			display: flex;
			align-self: stretch;
			margin-right: 30px;
			width: 32px;
			background: $eggColor;
			border-radius: 16px;
			@media(max-width: 768px) {
				img {
					width:32px;
					border-radius: 16px;
				}
			}
		}
		span{
			font-size: 3rem;
			line-height: 3.6rem;
			font-weight: 300;
			margin: 0;
			padding: 0;
			@media(max-width: 768px){
				font-size: 2.6rem;
				line-height: 3rem;
			}
		}
	}
	.text-desc{
		font-size: 1.375rem;
		line-height: 1.8rem;
		font-weight: normal;
		letter-spacing: -0.03rem;
		margin-top: 30px;
		@media(max-width: 768px){
			font-size: 1.03rem;
		}
		span{
			font-weight:bold;
			margin-bottom: 5px;
		}
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
