.btn-submit{
	background:$eggColor;
	color:$greyEgg;
	border:none;
	border-radius:30px;
	font-weight: bold;
	padding:20px 40px;
	transition:all 0.3s ease;
	cursor:pointer;
	&:hover{
		color:$eggColor;
		background:$greyEgg;
	}
}


.btn-white{
	@extend .btn-submit;
	background:white;
	color:$greyEgg;
}