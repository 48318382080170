@font-face {
  font-family: 'Sailec';
  src: url("../fonts/sailec/Sailec-Bold.eot");
  src: url("../fonts/sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/sailec/Sailec-Bold.woff") format("woff"), url("../fonts/sailec/Sailec-Bold.ttf") format("truetype"), url("../fonts/sailec/Sailec-Bold.svg#Sailec-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Sailec';
  src: url("../fonts/sailec/Sailec-Medium.eot");
  src: url("../fonts/sailec/Sailec-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/sailec/Sailec-Medium.woff2") format("woff2"), url("../fonts/sailec/Sailec-Medium.woff") format("woff"), url("../fonts/sailec/Sailec-Medium.ttf") format("truetype"), url("../fonts/sailec/Sailec-Medium.svg#Sailec-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Sailec';
  src: url("../fonts/sailec/Sailec-Light.eot");
  src: url("../fonts/sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/sailec/Sailec-Light.woff") format("woff"), url("../fonts/sailec/Sailec-Light.ttf") format("truetype"), url("../fonts/sailec/Sailec-Light.svg#Sailec-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Sailec';
  src: url("../fonts/sailec/Sailec.eot");
  src: url("../fonts/sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/sailec/Sailec.woff2") format("woff2"), url("../fonts/sailec/Sailec.woff") format("woff"), url("../fonts/sailec/Sailec.ttf") format("truetype"), url("../fonts/sailec/Sailec.svg#Sailec") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.capitalize{
  text-transform: capitalize;
}